import React from "react"

const Stage = () => (
  <section className="promotion">
    <div className="image"></div>
    <div className="content clearfix">
      <blockquote>
        Weil
        <br />
        ich dich
        <br />
        liebe.
      </blockquote>
      <p>Am 14. Februar ist Valentinstag!</p>
    </div>
  </section>
)

export default Stage
